<template>
  <div class="analyse-table">
    <el-table
      v-loading="loading"
      :data="tableData"
      tooltip-effect="light"
      stripe
      :height="'calc(100vh - 350px)'"
      style="width: 100%"
    >
      <el-table-column property="order_sn" label="订单号">
        <template #default="scope">
          <span :style="'color:' + 'var(--theme-color)'">
            {{ scope.row.order_sn }}
          </span></template
        >
      </el-table-column>
      <el-table-column property="name" label="门票名称">
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.name }}
          </span></template
        >
      </el-table-column>
      <el-table-column property="order_type" label="发生类型">
        <template #default="scope">
          <span :class="scope.row.order_type == 1 ? 'text-theme' : 'text-red'">
            {{ ["", "收入", "支出"][scope.row.order_type] }}
          </span>
        </template>
      </el-table-column>
      <el-table-column property="money" label="实收金额">
        <template #default="scope">
          <span :class="scope.row.order_type == 1 ? 'text-orange' : 'text-red'">
            {{ scope.row.money }}
          </span>
        </template>
      </el-table-column>
      <el-table-column property="payway_name" label="支付方式">
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.payway_name }}
          </span></template
        >
      </el-table-column>
      <el-table-column property="order_time" label="支付时间">
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.order_time }}
          </span></template
        >
      </el-table-column>
    </el-table>
    <div class="statistics-container">
      <div class="statistics-item">
        <span class="label">销售金额：</span>
        <span class="number"
          >￥{{ roundNumber(statisticsData.pay_money) }}</span
        >
      </div>
      <div class="statistics-item">
        <span class="label">退款金额：</span>
        <span class="number"
          >￥{{ roundNumber(statisticsData.refund_money) }}</span
        >
      </div>
      <div class="statistics-item">
        <span class="label">实际到账：</span>
        <span class="number text-orange">
          ￥{{
            roundNumber(
              roundNumber(statisticsData.pay_money) -
                roundNumber(statisticsData.refund_money)
            )
          }}
        </span>
      </div>
    </div>
    <div class="pagination-container">
      <el-pagination
        v-model:currentPage="listQuery.page"
        v-model:page-size="listQuery.limit"
        :page-sizes="[20, 50, 100, 200, 500]"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="listQuery.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentPageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { ref, watch, nextTick } from "vue";
import { AnalyseApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import { exportExcel, print } from "@/utils/common.js";

export default {
  props: {
    queryData: {
      type: Object,
      default: async () => {},
    },
  },
  setup(props) {
    const sendParmas = ref({});
    watch(
      () => props.queryData,
      (data) => {
        const lastData = JSON.parse(JSON.stringify(data));
        const parmas = {
          stime: lastData.stime,
          etime: lastData.etime,
          payway: lastData.payway,
          p_id: lastData.projectId,
          h_id: lastData.hotelId,
        };
        sendParmas.value = parmas;
        nextTick(() => {
          fetchData();
        });
      },
      {
        deep: true,
        immediate: true,
      }
    );
    const listQuery = ref({
      page: 1,
      limit: 20,
      total: 0,
    });

    const tableData = ref([]);
    const statisticsData = ref([]);

    const loading = ref(false);
    function fetchData() {
      const lastParmas = {
        search_type: 2,
        page: listQuery.value.page,
        limit: listQuery.value.limit,
        ...sendParmas.value,
      };
      loading.value = true;
      AnalyseApi.getHotelSummary(lastParmas).then((res) => {
        loading.value = false;
        if (res.Code === 200) {
          tableData.value = res.Data.list;
          statisticsData.value = res.Data.total;
          listQuery.value.total = res.Data.total.num;
        } else {
          let msg = res.Message ? res.Message : "获取数据失败！";
          ElMessage.error(msg);
        }
      });
    }
    function roundNumber(num) {
      return Math.round(+num * 100) / 100;
    }
    function handleSizeChange(val) {
      listQuery.value.limit = val;
      fetchData();
    }
    function handleCurrentPageChange(p) {
      listQuery.value.page = p;
      fetchData();
    }
    const propertiesConfig = ref([
      { field: "order_sn", displayName: "订单号" },
      { field: "name", displayName: "门票名称" },
      { field: "typeText", displayName: "发生类型" },
      { field: "money", displayName: "实收金额" },
      { field: "payway_name", displayName: "支付方式" },
      { field: "order_time", displayName: "支付时间" },
    ]);
    function processData(data) {
      data.length &&
        data.forEach((el) => {
          el.typeText = ["", "收入", "支出"][el.order_type];
        });
      return data;
    }
    function handlePrint() {
      print("酒店报表", processData(tableData.value), propertiesConfig.value);
    }
    function handleExport() {
      try {
        exportExcel(
          "酒店报表",
          processData(tableData.value),
          propertiesConfig.value
        );
        ElMessage.success({
          message: "导出成功！",
        });
      } catch (e) {
        ElMessage.error({
          message: "导出失败！",
        });
      }
    }
    return {
      tableData,
      loading,
      handleSizeChange,
      handleCurrentPageChange,
      listQuery,
      handleExport,
      handlePrint,
      statisticsData,
      roundNumber,
    };
  },
};
</script>

<style lang="scss">
</style>
